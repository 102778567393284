<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-row v-if="valider && test.statut_id == 6 && test.id > 0">
        <v-col cols="4">
          <v-select
            dense
            outlined
            label="Test type"
            v-model="additional_test.testtype_id"
            :rules="[(v) => !!v || 'Test type required']"
            :items="teststypes_list"
            :item-value="'id'"
            :item-text="'description'"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            v-model="additional_test.editcomment"
            :rules="[(v) => !!v || 'Comment required']"
            label="Comment"
            row-height="30"
            rows="2"
            variant="outlined"
            auto-grow
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-btn color="blue darken-1" @click.stop="add_test" class="ma-1">
            Add
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <listitems
      :list="tests"
      :headers="test_header"
      :toolbar="false"
      :key="ktests"
      @col_btn2_click="test_delete"
    >
    </listitems>
    <confirmdialog ref="validation" />
  </v-container>
</template>
<script>
import DeleteTest from "../graphql/WellTest/CCL/DELETE_TEST.gql";
import SAVE_EVENT from "../graphql/WellTest/APM/SaveEvent.gql";
import SaveTest from "../graphql/WellTest/APM/SaveTest.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    tests: Array,
    valider: Boolean,
    test: Object,
    teststypes_list: Array,
  },

  data() {
    return {
      additional_test: { id: 1, testtype_id: null, editcomment: null },
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      test_type: null,
      accept_comment: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      ktests: -2000,
      test_header: [
        {
          value: "id",
          selected: false,
        },
        {
          text: "Test",
          value: "testtype",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          sortable: false,
        },
      ],
    };
  },
  computed: {},
  created() {
    this.test_header[3].hiden = !(this.valider && this.test.statut_id == 6);
  },
  methods: {
    async add_test() {
      if (this.$refs.form.validate()) {
        let r = await this.$requette(
          SaveTest,
          {
            test: [
              {
                well_id: this.test.well_id,
                testtype_id: this.additional_test.testtype_id,
                planning_id: this.$store.state.planning[0].id,
                statut_id: 0,
              },
            ],
          },
          "no-cache"
        );
        if (r.ok) {
          let k = this.teststypes_list.findIndex(
            (x) => x.id == this.additional_test.testtype_id
          );
          this.additional_test.testtype = this.teststypes_list[k].description;
          this.cs++;
          this.ktests--;
          this.tests.push(this.additional_test);
          this.additional_test = {
            id: this.ktests,
            testtype_id: null,
            editcomment: null,
          };
          this.$refs.form.resetValidation();
          r = await this.$requette(
            SAVE_EVENT,
            {
              test_id: parseInt(r.data.SaveTest.id),
              user_id: this.$store.state.me.id,
              event_id: 2, // event returned
              event_statut_id: 0,
              comment: this.accept_comment,
            },
            "no-cache"
          );
          this.$emit("update");
          this.$emit("close");
          //this.test.statut_id = test_statut;
        }
      }
    },
    async test_delete(item) {
      if (item.id > 0) {
        if (
          await this.$refs.validation.open(
            "Confirmation",
            "Delete additionnal test",
            { color: "orange darken-3" }
          )
        ) {
          //DeleteTest
          let r = await this.$requette(
            DeleteTest,
            {
              id: parseInt(item.id),
            },
            "no-cache"
          );
          if (r.ok) {
            let k = this.tests.findIndex((x) => x.id == item.id);
            if (k > -1) {
              this.tests.splice(k, 1);
              this.ktests--;
              this.$emit("update");
            }
          }
        } else {
          let k = this.tests.findIndex((x) => x.id == item.id);
          if (k > -1) {
            this.tests.splice(k, 1);
            this.ktests--;
          }
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
